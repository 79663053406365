import { Dialog } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import CloseButton from "../../../common/CloseButton"
import VideoPlayer from "../../../common/VideoPlayer"

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 50px 0;
`
const StyledCloseButton = styled(CloseButton)`
  :focus {
    color: #fff;
    border: 1px solid #fff;
  }
`
const StyledVideo = styled(VideoPlayer)`
  max-width: 100%;
  width: 100%;
`

type VideoModalProps = {
  url: string
  open: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  label: string
  size?: "sm" | "md"
}

const VideoPopup = ({
  url,
  open,
  onClose,
  label,
  size = "md",
}: VideoModalProps) => {
  const handleclose = () => {
    onClose(false)
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={size}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          width: "100%",
          overflow: "hidden",
          maxHeight: "100%",
        },
      }}
      aria-label={`פופאפ לסרטון ${label}`}
    >
      <StyledContainer>
        <StyledCloseButton color="secondary" onClick={handleclose} />
        <StyledVideo url={url} playing light={false} label={label} />
      </StyledContainer>
    </Dialog>
  )
}
export default VideoPopup
