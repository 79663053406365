import { IconButton, IconButtonProps } from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import styled from "styled-components"

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;

  :focus {
    color: ${(p) => p.theme.colors.black};
  }
`

const CloseButton = (props: IconButtonProps) => (
  <StyledCloseButton autoFocus aria-label="סגירה" {...props}>
    <CloseIcon />
  </StyledCloseButton>
)

export default CloseButton
