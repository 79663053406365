import { Typography } from "@material-ui/core"
import { GeelyCarModelResponse } from "api"
import BottomButtons from "common/BottomButtons"
import { Desktop } from "common/MediaQueries"
import NextImage from "common/NextImage"
import { useState } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import PlayButton from "../../../common/PlayButton"
import VideoPopup from "./VideoPopup"

const StyledMainContainer = styled.div`
  height: 100%;
  overflow: hidden;
`
const StyledImageContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
const StyledGradientOverlay = styled.div<{ $inView: boolean }>`
  position: absolute;
  top: 0px;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 18%,
    #ffffff 66%
  );
  width: 100%;
  height: 100%;
  ${(p) => p.theme.breakpoints.up("md")} {
    top: 80px;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 0.97) 72%,
      ${(p) => p.theme.colors.white} 92%
    );
  }
`

const StyledContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
  ${(p) => p.theme.breakpoints.up("md")} {
    justify-content: flex-end;
  }
`

const StyledTextContainer = styled.div<{ $inView: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* ${(p) => p.theme.breakpoints.down("sm")} {
    transition: transform 300ms ease-in-out;
    ${(p) =>
    !p.$inView ? `transform: translateY(30%)` : ` transform: translateY(0)`};
  } */
  ${(p) => p.theme.breakpoints.up("md")} {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 200px 0 200px;
    transition: transform 300ms linear;
    ${(p) =>
      !p.$inView
        ? `transform: translateX(100%) translateY(120%)`
        : `transform: translateX(0) translateY(0)`};
  }
`
const StyledTitle = styled(Typography)<{ $inView: boolean }>`
  color: ${(p) => p.theme.colors.midnight};
  ${(p) => p.theme.breakpoints.down("sm")} {
    font-size: 26px;
    text-align: right;
    line-height: 1.08;
    margin: 0px 20px 30px 20px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 38px;
    width: 48%;
    text-align: left;
  }
`
const StyledDescription = styled(Typography)<{ $inView: boolean }>`
  font-size: 14px;
  color: ${(p) => p.theme.colors.greyishBrown};
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin: 0px 20px 40px 20px;
    line-height: 1.43;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    line-height: 1.78;
    width: 48%;
  }
`
const StyledPlayButton = styled(PlayButton)`
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-top: auto;
    margin-bottom: auto;
  }
`

const SEMSystem = ({ data }: { data: GeelyCarModelResponse }) => {
  const [openModal, setOpenModal] = useState(false)
  const semData = data.semSystemComponents[0]

  const { ref, inView } = useInView({
    threshold: 0.3,
  })
  return (
    <StyledMainContainer ref={ref}>
      <StyledImageContainer>
        {semData?.image.imageUrl && (
          <NextImage
            image={semData?.image.imageUrl}
            imageSizes={"100vw"}
            alt={semData?.image?.alt}
          />
        )}
        <StyledGradientOverlay $inView={inView} />
      </StyledImageContainer>
      <StyledContentContainer>
        <StyledPlayButton
          aria-label="פתיחת וידאו"
          onClick={() => setOpenModal(true)}
        />
        <VideoPopup
          url={semData?.video.imageUrl}
          open={openModal}
          onClose={setOpenModal}
          label={semData?.mainTitle}
        />
        <StyledTextContainer $inView={inView}>
          <StyledTitle $inView={inView}>{semData?.mainTitle}</StyledTitle>
          <StyledDescription $inView={inView}>
            {semData?.subTitle}
          </StyledDescription>
        </StyledTextContainer>
        <Desktop>
          <BottomButtons />
        </Desktop>
      </StyledContentContainer>
    </StyledMainContainer>
  )
}

export default SEMSystem
