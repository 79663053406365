import React, { useRef } from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"

const Styled = {
  PlayerWrapper: styled.div<{ $isFullHeight: boolean }>`
    ${(p) =>
      p.$isFullHeight
        ? `
      height:100%;
    `
        : `
    height: 0;
    padding-bottom: calc(100% * 9 / 16);
    `}

    width: 100%;
    position: relative;
  `,
  ReactPlayer: styled(ReactPlayer).attrs({
    muted: true,
    controls: true,
    width: "100%",
    height: "100%",
  })`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  `,
}

interface VideoPlayerProps {
  url: string
  height?: number
  playing?: boolean
  width?: number
  playIcon?: React.ReactElement
  light?: string | boolean
  isFullHeight?: boolean
  label?: string
}

export default function VideoPlayer(props: VideoPlayerProps) {
  const player = useRef()

  return (
    <Styled.PlayerWrapper $isFullHeight={props.isFullHeight}>
      <Styled.ReactPlayer
        url={props.url}
        height={props.height}
        playing={props.playing}
        width={props.width}
        playIcon={props.playIcon}
        light={props.light}
        ref={player}
        config={{
          file: {
            attributes: {
              "aria-label": `סרטון המתאר את ${props.label}`,
            },
          },
        }}
      />
    </Styled.PlayerWrapper>
  )
}
