import { Button } from "@material-ui/core"
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded"
import React from "react"
import styled from "styled-components"

const StyledPlayButton = styled(PlayArrowRoundedIcon)`
  margin: auto;
`

const StyledPlayButtonCircle = styled(Button)`
  width: 90px;
  height: 90px;
  margin-top: 80px;
  margin-bottom: 70px;
  background-color: ${(p) => p.theme.colors.white};
  -webkit-backdrop-filter: blur(4.6px);
  backdrop-filter: blur(4.6px);
  border-radius: 50%;
  opacity: 0.8;
  display: flex;
  font-size: 64px;
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 100px;
    height: 100px;
  }
`

const PlayButton = (props: { onClick?: () => void }) => (
  <StyledPlayButtonCircle {...props}>
    <svg width="0" height="0">
      <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
        <stop stopColor="#A238FF" offset="0%" />
        <stop stopColor="#0E7EAF" offset="100%" />
      </linearGradient>
    </svg>
    <StyledPlayButton
      fontSize="inherit"
      style={{ fill: "url(#blue-gradient)" }}
    />
  </StyledPlayButtonCircle>
)

export default PlayButton
